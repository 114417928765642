import { useState } from "react"

// Libraries
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

// Vars
const adminMenuItems = [
  {
    org: "DASA DE&C",
    pathname: "/users",
    title: "Users",
  },
  {
    org: "DASA DE&C",
    pathname: "/SAPBR",
    title: "SAPBR",
  },
  {
    org: "DASA DE&C",
    pathname: "/TDA-RFIs",
    title: "TDA",
  },
  {
    org: "DASA DE&C",
    pathname: "/FiscalCycles",
    title: "Fiscal Cycles",
  },
  {
    org: "DASA DE&C",
    pathname: "/Dashboards",
    title: "Dashboards",
  },
  {
    org: "DASA DE&C",
    pathname: "/Models",
    title: "All Models",
  },
  {
    org: "DASA DE&C",
    pathname: "/Reference",
    title: "Reference Tables",
  },
  // {
  //   org: "DASA DE&C",
  //   pathname: "/query-test",
  //   title: "HERE",
  // },
]

export const AdminMenu = ({ handleClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const toggleMenuVisibility = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAdminClick = (org, pathname) => {
    handleClose()
    handleClick(org, pathname)
  }

  const getAdminMenuItems = () => {
    return adminMenuItems.map((item, index) => {
      let { org, pathname, title } = item
      return (
        <MenuItem
          key={index}
          variant="navMenu"
          sx={{ borderBottom: "1px solid #dbdbdb" }}
          onClick={() => handleAdminClick(org, pathname)}
        >
          {title}
          <Divider color="gray" />
        </MenuItem>
      )
    })
  }

  return (
    <Box className="dashNavBtn">
      <Button
        className="dashNavBtn dashBtn"
        variant="outlined"
        onClick={toggleMenuVisibility}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: "white",
          borderColor: "white",
          marginRight: "5px",
        }}
      >
        <Typography
          variant="navbarBtn"
          noWrap
        >
          ADMIN
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiList-root": {
            padding: "0px",
          },
        }}
      >
        {getAdminMenuItems()}
      </Menu>
    </Box>
  )
}
