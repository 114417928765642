import React from "react"
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from "@mui/material"
import PEO_PM_Utils from "./PEO-PM/PEO_PM_Utils"

export const SubmitRFIWarningModal = ({ showSubmitRFIWarningModal, setShowSubmitRFIWarningModal, msg, unassociatedRows, submitRFIData }) => {
  return (
    <Dialog
      open={showSubmitRFIWarningModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Submit RFI Data?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {unassociatedRows?.length > 0
            ? msg + " The following rows do not have their UserCaseId and LineId associated and will not be saved in this submission:"
            : msg}
        </DialogContentText>
        {unassociatedRows?.length > 0 && (
          <ul>
            {unassociatedRows.map((row, index) => (
              <DialogContentText
                key={index}
                id="alert-dialog-description"
              >
                <li style={{ margin: "0.5rem 0" }}>
                  UserCaseId: {row[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]}, LineId: {row[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]}
                </li>
              </DialogContentText>
            ))}
          </ul>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "black", border: "1px solid black" }}
          onClick={() => setShowSubmitRFIWarningModal(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "red", color: "white", fontWeight: "bold", border: "1px solid black" }}
          onClick={() => {
            submitRFIData()
            setShowSubmitRFIWarningModal(false)
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
