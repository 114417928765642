import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Select, TextField, InputLabel } from '@mui/material'
import './AddOrganizationPOCModal.css'
import CloseIcon from '@mui/icons-material/Close'
import { apiCalls } from '../DataService'
import GlobalContext from '../reducers/GlobalContext'
import { OrganizationRoles } from '../../Utils'
import { Menu } from '@mui/icons-material'

const AddOrganizationPOCModal = ({isOpen, toggleModal, editParams, type}) => {
    const [POCs, setPOCs] = useState([])
    const [users, setUsers] = useState([])
    const [formInput, setFormInput] = useState({
        poc: '',
        role: '',
    })
    const [showInputErrors, setShowInputErrors] = useState(false)
    const [POCError, setPOCError] = useState('')
    const [roleError, setRoleError] = useState('')
    const [selectedPOC, setSelectedPOC] = useState({})
    const [selectedRole, setSelectedRole] = useState('View Only')
    const globalContext = useContext(GlobalContext)
    const {selectedOrg} = globalContext

    const handleChange = async (e, inputType) => {
        e.preventDefault()
        switch (inputType) {
            case 'POC':
                const pocArr = e.target.value.split(" ")
                setFormInput(prevState => {
                    return {...prevState, poc: `${pocArr[1]} ${pocArr[2]}`}
                })
                await apiCalls.getById(`Pocs`, pocArr[0]).then(tempPOC => {
                    // Set the OrgPOC link's role to selected role here
                    setSelectedPOC({...tempPOC, role: selectedRole})
                    const matchingUser = users.find(user => user.userName === tempPOC.userName)
                    setSelectedPOC(prevState => {
                        return {...prevState, email: matchingUser.email ? matchingUser.email : '', phone: matchingUser.phoneNumber ? matchingUser.phoneNumber: ''}
                    })
                })
                break
            case 'role':
                setSelectedRole(e.target.value)
                setFormInput(prevState => {
                    return {...prevState, role: e.target.value}
                })
                setSelectedPOC(prevState => {
                    return {...prevState, role: e.target.value}
                })
                break
            default:
                break
        }
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault()
        if (!POCError && ! roleError) {
            // Make POST or PUT DB call to add or edit a POC
            setShowInputErrors(false)
            switch (type) {
                case 'assign':
                    // Add the POC to the selected organization
                    await apiCalls.post(`Organizations/${selectedOrg.id}/Pocs/${selectedPOC.id}/${selectedRole}`, null)
                    break
                case 'edit':
                    // Update the role of the POC link related to the selectedPOC that is associated with the selectedOrganization
                    await apiCalls.put(`Organizations/${selectedOrg.id}/Pocs/${selectedPOC.id}?role=${selectedRole}`, null)
                    break
                default:
                    break
            }
            toggleModal()
            setFormInput({
                poc: '',
                role: '',
            })
        } else setShowInputErrors(true)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setShowInputErrors(false)
        setFormInput({
            poc: '',
            role: '',
        })
        setSelectedPOC({})
        toggleModal()
    }

    useEffect(() => {
        async function fetchSelectedPOC() {
            if (editParams) {
                // Set selected POC to the POC we're editing if in edit mode
                apiCalls.getById(`Pocs`, editParams.id).then(data => {
                    setSelectedPOC(data)
                })
            }
        }
        fetchSelectedPOC()
    }, [editParams])

    useEffect(() => {
        // Error handling
        if (!formInput.poc.length && type === 'assign') {
            setPOCError('* Please select a POC')
        } else setPOCError('')
        if (!formInput.role.length) {
            setRoleError('* Please select a role')
        } else setRoleError('')
    }, [formInput])

    useEffect(() => {
        async function setUsersAndPOCs () {
            // Retrieve User from DB
            const tempUsers = await apiCalls.getAll(`Accounts`, {})
            setUsers(tempUsers)
            // Retrieve POCs from DB
            let tempPOCs = await apiCalls.getAll(`Pocs`, {})
            tempPOCs = tempPOCs.filter(poc => {
                // Check for matching user in User Accounts table
                const matchingUser = tempUsers.find(user => user.userName === poc.userName)
                // Make sure the POC doesn't already exist in the table
                const existsInTable = selectedOrg.pocList ? selectedOrg.pocList.map(p => p.id).includes(poc.id) : false
                if (matchingUser && !existsInTable) return true
                else return false
            })
            setPOCs(tempPOCs)
        }
        setUsersAndPOCs()
    }, [selectedOrg.pocList])

    return (
        <Dialog
            open={isOpen}
            onClose={handleCancel}
        >
            {type === 'edit' ?
                <DialogTitle>Edit POC for {selectedOrg.name} - {selectedOrg.longName}</DialogTitle>
            :    
                <DialogTitle>Assign POC for {selectedOrg.name} - {selectedOrg.longName}</DialogTitle>
            }
            <DialogContent dividers>
                <form className='poc-form'>
                    <div className='poc-role'>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='-POCs'>POC <span className='asterick'>*</span></InputLabel>
                            <Select name="POCs" id="-POCs" className='poc-input' onChange={(e) => handleChange(e, 'POC')}>
                                <MenuItem value='select-poc' hidden>Select</MenuItem>
                                {POCs.length > 0 && POCs.map(poc => (
                                    <MenuItem key={poc.id} value={`${poc.id} ${poc.firstName} ${poc.lastName}`}>{poc.firstName} {poc.lastName}</MenuItem>
                                ))}
                            </Select>
                            {POCError.length > 0 && showInputErrors ? (
                                <p className='error-msg'>{POCError}</p>
                            ) : null}
                        </div>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='role'>Organization Role<span className='asterick'>*</span></InputLabel>
                            <Select name="roles" id="-roles" className='poc-input' onChange={(e) => handleChange(e, 'role')}>
                                <MenuItem value="select-role" hidden>Select</MenuItem>
                                <MenuItem value={OrganizationRoles.VIEW_ONLY}>{OrganizationRoles.VIEW_ONLY}</MenuItem>
                                <MenuItem value={OrganizationRoles.USER_ACCESS_DATA_LOADER}>{OrganizationRoles.USER_ACCESS_DATA_LOADER}</MenuItem>
                                <MenuItem value={OrganizationRoles.FINAL_APPROVER}>{OrganizationRoles.FINAL_APPROVER}</MenuItem>
                                <MenuItem value={OrganizationRoles.SUPER_USER}>{OrganizationRoles.SUPER_USER}</MenuItem>
                            </Select>
                            {roleError.length > 0 && showInputErrors ? (
                                <p className='error-msg'>{roleError}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className='fname-lname'>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='first-name'>First Name</InputLabel>
                            <TextField type='text' id='first-name' className='poc-input' disabled value={selectedPOC.firstName}/>
                        </div>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='last-name'>Last Name</InputLabel>
                            <TextField type='text' id='last-name' className='poc-input' disabled value={selectedPOC.lastName}/>
                        </div>
                    </div>
                    <div className='email-phone'>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='email'>Email</InputLabel>
                            <TextField type='email' id='email' className='poc-input' disabled value={selectedPOC.email}/>
                        </div>
                        <div className='input-field'>
                            <InputLabel className='label' htmlFor='phone'>Phone</InputLabel>
                            <TextField type='text' id='phone' className='poc-input' disabled value={selectedPOC.phone}/>
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button className='poc-modal-btn' onClick={handleCancel}>Cancel</Button>
                {type === 'edit' ? 
                    <Button className='poc-modal-btn' onClick={(e) => handleSubmit(e, 'edit')}>Confirm</Button>
                :
                    <Button className='poc-modal-btn' onClick={(e) => handleSubmit(e, 'assign')}>Assign</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default AddOrganizationPOCModal