import React from 'react'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import '../RFI.css'

const TDA_Instructions_Modal = ({disclaimerRFIModalOpen, setDisclaimerRFIModalOpen}) => {
    return (
        <Modal
            open={disclaimerRFIModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        > 
            <div className='--RFI-disclaimer-modal-container'>
                <div className='--RFI-disclaimer-modal-header'>
                    <h4>RFI Instructions - TDA</h4>
                    <CloseIcon fontSize='large' onClick={() => setDisclaimerRFIModalOpen(false)}/>
                </div>
                <div className='--RFI-disclaimer-modal-body'>
                    <div className='--RFI-disclaimer-modal-messages'>
                        <div>
                            <p>Using FMSWeb, go to reports, personnel, and filter MDEP on JDFM. Manually generate and compile reports for each FY. You will need last FY, current FY, and next FY. Add the FY and UIC columns. FY is based on
the last two characters of the CCNUM. UIC is the last 6 characters of DOCNO. </p>
                        </div>
                    </div>
                    <button className='--RFI-close-RFI-modal-btn' onClick={() => setDisclaimerRFIModalOpen(false)}>Close</button>
                </div>
            </div>
        </Modal>
    )
}

export default TDA_Instructions_Modal
