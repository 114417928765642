import { Box, Button } from "@mui/material"

const MHWLBtns = ({ fetchData }) => {
  return (
    <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginRight: "3.5rem" }}>
      <Button
        onClick={() => fetchData()}
        variant="contained"
        sx={{
          marginLeft: "5px",
          backgroundColor: "#225782f4",
          "&:hover": {
            backgroundColor: "#225782",
          },
        }}
      >
        RUN MODELS
      </Button>
    </Box>
  )
}

export default MHWLBtns
