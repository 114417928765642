import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {apiCalls, QueryParamTypes} from '../../DataService';
import { AllOrganizationRoles } from '../../../Utils';
import {Checkbox, Modal, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button} from '@mui/material'

export const UserOrgRoleMultipleSelect = ({isMultSelectModalOpen, toggleMultSelectModal, userParams, selectedUserId}) => {
    const [organizations, setOrganizations] = useState([])
    const [pocs, setPocs] = useState([])
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedPOC, setSelectedPOC] = useState({})
    const [selectedOrgRoleMap, setSelectedOrgRoleMap] = useState({})
    const [orgRoleNewMappings, setOrgRoleNewMappings] = useState({})
    const [showAssignUserToOrgRolesWarningDialog, setShowAssignUserToOrgRolesWarningDialog] = useState(false)


    const handleChange = (e, field, orgName) => {
        switch (field) {
            case 'role-select':
                setSelectedOrgRoleMap({...selectedOrgRoleMap, [orgName]: {
                    initialRole: selectedOrgRoleMap[orgName]?.initialRole ? selectedOrgRoleMap[orgName].initialRole : null,
                    role: e.target.value,
                    remove: false,
                }})
                break
            case 'remove-org':
                setSelectedOrgRoleMap({...selectedOrgRoleMap, [orgName]: {
                    initialRole: selectedOrgRoleMap[orgName]?.initialRole ? selectedOrgRoleMap[orgName].initialRole : null,
                    role: selectedOrgRoleMap[orgName]?.role,
                    remove: !selectedOrgRoleMap[orgName]?.remove
                }})
                break
            default:
                break
        }
    }

    const handleCancel = () => {
        toggleMultSelectModal()
    }

    const handleAssignRolesInOrgs = async () => {
        console.log(organizations, orgRoleNewMappings)
        for (const orgName of Object.keys(orgRoleNewMappings)) {
            console.log(orgName)
            const orgId = organizations.find(org => org.name === orgName).id
            if (selectedOrgRoleMap[orgName]?.remove) {
                // Remove user from the organization
                await apiCalls.delete(`Organizations/${orgId}/Pocs/${selectedPOC.id}`)
            } else {
                if (!selectedOrgRoleMap[orgName]?.initialRole) {
                    // Add user to the organization with role
                    await apiCalls.post(`Organizations/${orgId}/Pocs/${selectedPOC.id}/${selectedOrgRoleMap[orgName]?.role}`, null)
                } else {
                    // Changes user's role in the organization
                    await apiCalls.put(`Organizations/${orgId}/Pocs/${selectedPOC.id}?role=${selectedOrgRoleMap[orgName]?.role}`, null)
                }
            }
        }
        toggleMultSelectModal()
    }

    const isRoleSelected = (orgName, role) => {
        return Object.keys(selectedOrgRoleMap).includes(orgName) && role === selectedOrgRoleMap[orgName]?.role
    }

    const canAssignOrgsRoles = () => {
        let assignLocked = true
        for (let i = 0; i < Object.keys(selectedOrgRoleMap).length; i++) {
            const orgName = Object.keys(selectedOrgRoleMap)[i]
            if (selectedOrgRoleMap[orgName]?.initialRole !== selectedOrgRoleMap[orgName]?.role || selectedOrgRoleMap[orgName]?.remove) {
                assignLocked = false
                break
            }
        }
        return !assignLocked
    }

    const getOrgRoleNewMappings = () => {
        let tmpNewMappings = {}
        Object.keys(selectedOrgRoleMap).forEach(orgName => {
            if (selectedOrgRoleMap[orgName]?.role !== selectedOrgRoleMap[orgName]?.initialRole || selectedOrgRoleMap[orgName]?.remove) {
                tmpNewMappings = {...tmpNewMappings, [orgName]: {
                    initialRole: selectedOrgRoleMap[orgName]?.initialRole,
                    role: selectedOrgRoleMap[orgName]?.role,
                    remove: selectedOrgRoleMap[orgName]?.remove,
                }}
            }
        })
        setOrgRoleNewMappings(tmpNewMappings)
    }

    const getAllOrgsAndPOCs = () => {
        apiCalls.getAll(`Organizations`, {[QueryParamTypes.ORDER_BY]: 'name'}).then(data => {
            setOrganizations(data)
        })
        apiCalls.getAll(`Pocs`, {[QueryParamTypes.ORDER_BY]: 'lastName'}).then(data => {
            setPocs(data)
        })
    }

    useEffect(() => {
        getOrgRoleNewMappings()
    }, [selectedOrgRoleMap])

    useEffect(() => {
        if (selectedPOC?.organizationList) {
            let tmpMap = {}
            selectedPOC.organizationList.forEach(orgRoleObj => {
                tmpMap = {...tmpMap, [orgRoleObj.organization.name]: {
                    initialRole: orgRoleObj.role,
                    role: orgRoleObj.role,
                    remove: false,
                }}
            })
            setSelectedOrgRoleMap(tmpMap)
        }
    }, [selectedPOC])

    useEffect(() => {
        if (selectedUser.userName) {
            const tmpSelectedPOC = pocs.find(poc => poc.userName === selectedUser.userName)
            setSelectedPOC(tmpSelectedPOC)
        }
    }, [selectedUser, pocs])

    useEffect(() => {
        if (selectedUserId) {
            apiCalls.getById(`Accounts`, selectedUserId).then(data => {
                setSelectedUser(data)
            })
        }
    }, [selectedUserId])

    useEffect(() => {
        if (isMultSelectModalOpen) {
            getAllOrgsAndPOCs()
        }
    }, [isMultSelectModalOpen])

    useEffect(() => {
        if (isMultSelectModalOpen) {
            getAllOrgsAndPOCs()
        }
    }, [])

    return (
        <div>
            <Modal
                open={isMultSelectModalOpen}
                style={{overflow: 'auto'}}
                sx={{
                    minWidth: 800,
                }}
            >
                <div className='user-modal-container'>
                    <div className='user-modal-header'>
                        <div>
                            <h1>Assign {userParams.firstName} {userParams.lastName} to roles in organizations</h1>
                        </div>
                        <button onClick={handleCancel}><CloseIcon sx={{color: '#FFF'}}/></button>
                    </div>
                    <div className='user-form'>
                        {organizations.map((org, key) => (
                            <div className='user-org-role-mult-select' key={key}>
                                <div className='user-input-field' style={{flex: 2}}>
                                    <h4>{org.name}</h4>
                                </div>
                                <div className='user-input-field' style={{flex: 4}}>
                                    <label className='label' htmlFor='role'>Assign role in {org.name}</label>
                                    <select disabled={selectedOrgRoleMap[org.name]?.remove} name="roles" id="-roles" className='poc-input' onChange={(e) => handleChange(e, 'role-select', org.name)}>
                                        {!Object.keys(selectedOrgRoleMap).includes(org.name) && <option value="select-role" hidden>Select</option>}
                                        {AllOrganizationRoles.map((role, idx) => (
                                            <option key={idx} value={role} selected={isRoleSelected(org.name, role)} hidden={isRoleSelected(org.name, role)}>{role}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='user-remove-checkbox' style={{flex: 2}}>
                                    {selectedPOC?.organizationList?.map(orgRoleObj => orgRoleObj.organization.name)?.includes(org.name) &&
                                        <div>
                                            <label className='label'>Remove from {org.name}</label>
                                            <Checkbox checked={selectedOrgRoleMap[org.name]?.remove == undefined ? false : selectedOrgRoleMap[org.name]?.remove} onChange={(e) => {handleChange(e, 'remove-org', org.name)}}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='user-modal-buttons'>
                        <button onClick={handleCancel} className='user-modal-btn'>Cancel</button>
                        <button onClick={() => setShowAssignUserToOrgRolesWarningDialog(true)} disabled={!canAssignOrgsRoles()} className='user-modal-btn'>Assign</button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={showAssignUserToOrgRolesWarningDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add {selectedPOC?.firstName && selectedPOC?.lastName ? `${selectedPOC.firstName} ${selectedPOC.lastName}` : 'this POC'} to roles in selected organizations
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to assign {selectedPOC?.firstName && selectedPOC?.lastName ? `${selectedPOC.firstName} ${selectedPOC.lastName} ` : 'this POC '}
                        to the selected roles in the organizations listed below. Please confirm this is all correct.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        <ul>
                            {Object.keys(orgRoleNewMappings).map((orgName, idx) => {
                                // Removing an org
                                if (selectedOrgRoleMap[orgName]?.remove) {
                                    return (<li key={idx}>Remove from <strong>{orgName}</strong></li>)
                                }
                                // Adding an org
                                if (!selectedOrgRoleMap[orgName]?.initialRole) {
                                    return (<li key={idx}>Add to <strong>{orgName}</strong> as <strong>{selectedOrgRoleMap[orgName]?.role}</strong></li>)
                                } else {
                                // Changing roles in an org
                                    return (<li key={idx}>Change role in <strong>{orgName}</strong> from <strong>{selectedOrgRoleMap[orgName]?.initialRole}</strong> to <strong>{selectedOrgRoleMap[orgName]?.role}</strong></li>)
                                }
                            })}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: 'black', border: '2px solid black'}} onClick={() => setShowAssignUserToOrgRolesWarningDialog(false)}>Cancel</Button>
                    <Button style={{backgroundColor: 'red', color: 'white', fontWeight: 'bold', border: '2px solid black'}} onClick={() => {
                        handleAssignRolesInOrgs()
                        setShowAssignUserToOrgRolesWarningDialog(false)
                    }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
