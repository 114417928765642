import { useState, useEffect, useMemo } from "react"

// Data
import { apiCalls } from "./components/DataService"

// Context
import AppContext from "./AppContext"

// Routes
import AppRoutes from "./AppRoutes"

// Reducers
import GlobalState from "./components/reducers/GlobalState"

// Query Library
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

//Styles
import { ThemeProvider } from "@mui/material"
import theme from "./theme"
import CssBaseline from '@mui/material/CssBaseline';

// MUI Licensing
import { LicenseInfo } from "@mui/x-license"
import { createFTEDashboardInitObj } from "./components/DashboardMenu"
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants"
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

const App = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const [loading, setLoading] = useState(true)

  const [clickTarget, setClickTarget] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState("")

  const [classificationData, setClassificationData] = useState("")
  const [selectedFY, setSelectedFY] = useState("")
  const [allFiscalCycles, setAllFiscalCycles] = useState("")
  const [rfiOrgMapping, setRfiOrgMapping] = useState("")
  const [allOrganizations, setAllOrganizations] = useState("")

  // const [user, setUser] = useState("")

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **
  const context = {
    classificationData,
    clickTarget,
    selectedOrg,
    selectedFY,
    setSelectedFY,
    allFiscalCycles,
    rfiOrgMapping,
  }

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    allFiscalCycles && getFY()
  }, [allFiscalCycles])

  useEffect(() => {
    if (classificationData && selectedFY) {
      setLoading(false)
    }
  }, [classificationData, selectedFY])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const getUser = async () => {
    if (sessionStorage.getItem("currentUser")) {
      return
    }
    try {
      let response = await apiCalls.fetchData("Accounts/me")
      if (response.status === 200) {
        sessionStorage.setItem("currentUser", JSON.stringify(response.data))
      }
    } catch (e) {
      console.log("e: ", e)
      window.location.replace(ApplicationPaths.IdentityLoginPath)
    }
  }

  const init = async () => {
    getUser()

    let classFetch = apiCalls.fetchData("Configurations/Classification")
    let fiscalFetch = apiCalls.fetchData("FiscalCycles")
    let rfiMapFetch = apiCalls.fetchData("RfiOrganizationsMappings")
    let orgFetch = apiCalls.fetchData("Organizations")

    let [classResponse, fiscalResponse, rfiMapResponse, orgResponse] = await Promise.all([classFetch, fiscalFetch, rfiMapFetch, orgFetch])

    let rfiMappingData = createFTEDashboardInitObj(rfiMapResponse.data)
    setRfiOrgMapping(rfiMappingData)
    setAllFiscalCycles(fiscalResponse.data)
    setClassificationData(classResponse.data)
    setAllOrganizations(orgResponse.data)

    sessionStorage.setItem("classificationData", JSON.stringify(classResponse.data))
  }

  const getFY = () => {
    let sessionData = sessionStorage.getItem("FY")
    let fyObj
    let slicedNum

    if (!sessionData) {
      slicedNum = Number(allFiscalCycles[0].fiscalYear.slice(2)) + 3

      fyObj = {
        ...allFiscalCycles[0],
        SAPBR: `FY${slicedNum}`,
      }

      setSelectedFY(fyObj)
      sessionStorage.setItem("FY", JSON.stringify(fyObj))
      return
    }

    if (sessionData) {
      let parsed = JSON.parse(sessionData)
      slicedNum = Number(parsed.fiscalYear.slice(2)) + 3
      let sapbrStr = `FY${slicedNum}`

      fyObj = {
        ...parsed,
        SAPBR: sapbrStr,
      }
      sessionData && setSelectedFY(fyObj)
    }
  }

  const queryClient = new QueryClient()

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalState >
        <AppContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <div
              className="App"
              onClick={(e) => setClickTarget(e.target)}
            >
              {!loading && <AppRoutes loading={loading} />}
            </div>
            <ReactQueryDevtools initialIsOpen={true} />
          </QueryClientProvider>
        </AppContext.Provider>
      </GlobalState>
    </ThemeProvider>
  )
}

export default App
