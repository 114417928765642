import { useContext } from "react"

// MUI
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"

// Context
import AppContext from "../../AppContext"

// Styles
import "./styles/DataUpdateBtnStyles.css"

export const DataUpdateBtn = () => {
  const context = useContext(AppContext)

  const getNum = (num) => {
    return Number(num.slice(2)) + 3
  }

  const sortedFC = () => {
    return context.allFiscalCycles.sort((itemA, itemB) => {
      return getNum(itemA.fiscalYear) - getNum(itemB.fiscalYear)
    })
  }

  const handleChange = (e) => {
    let slicedNum = getNum(e.target.value.fiscalYear)
    let sapbrStr = `FY${slicedNum}`

    let fyObj = {
      ...e.target.value,
      SAPBR: sapbrStr,
    }
    context.setSelectedFY(fyObj)
    sessionStorage.setItem("FY", JSON.stringify(fyObj))
  }

  const getMenuItems = () => {
    return sortedFC().map((item, index) => {
      return (
        <MenuItem
          className="dashNavBtn"
          key={index}
          value={item}
        >
          <Typography>{item.fiscalYear}</Typography>
        </MenuItem>
      )
    })
  }

  return (
    <FormControl className="dashBtn dashNavBtn">
      <InputLabel
        id="plz"
        className="navButton"
        sx={{ color: "white" }}
      >
        Fiscal Year
      </InputLabel>
      <Select
        onChange={handleChange}
        className="navButton dashNavBtn"
        label="Fiscal Year"
        renderValue={(a) => {
          return <Typography variant="navbarBtn">{a.fiscalYear}</Typography>
        }}
        value={context.selectedFY}
        sx={{
          color: "white",
          marginRight: "5px",
          padding: "5px 15px",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          ".MuiSelect-icon": {
            color: "white",
          },
          ".MuiOutlinedInput-input": {
            padding: "0px",
          },
        }}
      >
        {getMenuItems()}
      </Select>
    </FormControl>
  )
}
