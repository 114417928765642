import { useState } from "react"

// Libraries
import { useHistory } from "react-router-dom"
import { Box, Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"

// Vars
const workloadMenuItems = [
  {
    org: "USASAC",
    pathname: "/DSAMS-CISIL-RFIs",
    title: "DSAMS/CISIL",
  },
  {
    org: "ACC",
    pathname: "/ACC-RFIs",
    title: "ACC",
  },
  {
    org: "SATFA",
    pathname: "/SATFA-RFIs",
    title: "SATFA",
  },
]

const workforceMenuItems = [
  {
    title: "GFEBS/CFEMS",
    pathname: "/GFEBS-CEFMS-RFIs",
  },
  {
    title: "Supplemental Workforce",
    pathname: "/SupplementalWorkforce-RFIs",
  },
]

export const ClaimantRfisMenu = ({
  handleWorkloadClick,
  handleWorkforceClick,
  SelectedTabToRFIMapping,
  RFIViewOrgMappings,
  selectedOrg,
  setTargetOrganizationInGlobalContext,
  setSelectedTabRfiMappingKeyContext,
}) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleWorkloadMenuItemClick = (org, pathname) => {
    handleClose()
    handleWorkloadClick(org, pathname)
  }

  const handleWorkforceMenuClick = (pathname) => {
    handleClose()
    handleWorkforceClick(pathname)
  }

  const getWorkloadMenuItems = (arr) => {
    return arr.map((item, index) => {
      let { org, pathname, title } = item
      return (
        <MenuItem
          key={index}
          variant="navMenu"
          onClick={() => handleWorkloadMenuItemClick(org, pathname)}
        >
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="1px" />
          </ListItemIcon>
          {title}
        </MenuItem>
      )
    })
  }

  const getWorkforceMenuItems = (arr) => {
    return arr.map((item) => {
      let { title, pathname } = item
      return (
        <MenuItem
          key={title}
          variant="navMenu"
          onClick={() => handleWorkforceMenuClick(pathname)}
        >
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="1px" />
          </ListItemIcon>
          {title}
        </MenuItem>
      )
    })
  }

  return (
    <Box>
      <Button
        className="dashNavBtn dashBtn"
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: "white",
          borderColor: "white",
          marginRight: "5px",
        }}
      >
        <Typography
          variant="navbarBtn"
          noWrap
        >
          Claimant RFIs
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiList-root": {},
        }}
      >
        <Typography variant="navbarMenuHeader">WORKLOAD</Typography>
        <Divider variant="navMenu" />
        {getWorkloadMenuItems(workloadMenuItems)}

        <Typography variant="navbarMenuHeader">WORKLOAD VALIDATION</Typography>
        <Divider variant="navMenu" />
        <MenuItem
          key="navUSACE"
          variant="navMenu"
          onClick={() => {
            setTargetOrganizationInGlobalContext("USACE")
            setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.USACE)
            history.push({
              pathname: `/PEO-PM-RFIs`,
              state: { organization: "USACE" },
            })
            handleClose()
          }}
        >
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="1px" />
          </ListItemIcon>
          USACE
        </MenuItem>
        <MenuItem
          key="navPEOPM"
          variant="navMenu"
          onClick={() => {
            if (!RFIViewOrgMappings["/PEO-PM-RFIs"].includes(selectedOrg?.name)) {
              setTargetOrganizationInGlobalContext("JPEO A&A")
              setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.PEO_PM)
            }
            history.push("/PEO-PM-RFIs")
            handleClose()
          }}
        >
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="1px" />
          </ListItemIcon>
          PEO PM
        </MenuItem>
        <MenuItem
          key="navSATMO"
          variant="navMenu"
          onClick={() => {
            setTargetOrganizationInGlobalContext("SATMO")
            setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.SATMO)
            history.push({
              pathname: `/PEO-PM-RFIs`,
              state: { organization: "SATMO" },
            })
            handleClose()
          }}
        >
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="1px" />
          </ListItemIcon>
          SATMO
        </MenuItem>

        <Typography variant="navbarMenuHeader">WORKFORCE</Typography>
        <Divider variant="navMenu" />
        {getWorkforceMenuItems(workforceMenuItems)}
      </Menu>
    </Box>
  )
}
