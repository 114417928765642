import React, { useState, useEffect, useContext, useRef } from "react"
import dayjs from "dayjs"
import { useLocation } from "react-router-dom"
import "./OrganizationPOCs.css"
import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from "@mui/icons-material/Close"
import orgIconPlaceholder from "../../CWATImages/OrganizationIconPlaceholder.png"
import pdfIcon from "../../CWATImages/pdf.png"
import csvIcon from "../../CWATImages/csv.png"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import AddOrganizationPOCModal from "./AddOrganizationPOCModal"
import { apiCalls, QueryParamTypes } from "../DataService"
import { CSVLink } from "react-csv"
import GlobalContext from "../reducers/GlobalContext"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import { MinTableHeight } from "../../Utils"

import ACC from "./FMS_Organization_Icons/ACC.png"
import AMCOM from "./FMS_Organization_Icons/AMCOM.png"
import CECOM from "./FMS_Organization_Icons/CECOM.png"
import DASA_DEC from "./FMS_Organization_Icons/DASA_DE&C.png"
import JMC from "./FMS_Organization_Icons/JMC.png"
import JPEO_A_A from "./FMS_Organization_Icons/JPEO_A&A.png"
import JPEO_CBRND from "./FMS_Organization_Icons/JPEO_CBRND.png"
import MASPO from "./FMS_Organization_Icons/MASPO.png"
import PEO_AVN from "./FMS_Organization_Icons/PEO_AVN.png"
import PEO_CS_CSS from "./FMS_Organization_Icons/PEO_CS&CSS.png"
import PEO_EIS from "./FMS_Organization_Icons/PEO_EIS.png"
import PEO_GCS from "./FMS_Organization_Icons/PEO_GCS.png"
import PEO_IEW_S from "./FMS_Organization_Icons/PEO_IEW&S.png"
import PEO_M_S from "./FMS_Organization_Icons/PEO_M&S.png"
import PEO_SOLDIER from "./FMS_Organization_Icons/PEO_Soldier.png"
import PEO_STRI from "./FMS_Organization_Icons/PEO_STRI.png"
import PM_LAV from "./FMS_Organization_Icons/PM_LAV.png"
import SATFA from "./FMS_Organization_Icons/SATFA.png"
import SATMO from "./FMS_Organization_Icons/SATMO.png"
import TACOM from "./FMS_Organization_Icons/TACOM.png"
import USACE from "./FMS_Organization_Icons/USACE.png"
import USAMMA from "./FMS_Organization_Icons/USAMMA.png"
import USASAC from "./FMS_Organization_Icons/USASAC.png"

export const logosArr = [
  { name: "ACC", link: ACC },
  { name: "AMCOM", link: AMCOM },
  { name: "CECOM", link: CECOM },
  { name: "DASA DE&C", link: DASA_DEC },
  { name: "JMC", link: JMC },
  { name: "JPEO A&A", link: JPEO_A_A },
  { name: "JPEO CBRND", link: JPEO_CBRND },
  { name: "PEO MASPO", link: MASPO },
  { name: "PEO AVN", link: PEO_AVN },
  { name: "PEO CS&CSS", link: PEO_CS_CSS },
  { name: "PEO EIS", link: PEO_EIS },
  { name: "PEO GCS", link: PEO_GCS },
  { name: "PEO IEW&S", link: PEO_IEW_S },
  { name: "PEO M&S", link: PEO_M_S },
  { name: "PEO Soldier", link: PEO_SOLDIER },
  { name: "PEO STRI", link: PEO_STRI },
  { name: "PM LAV", link: PM_LAV },
  { name: "SATFA", link: SATFA },
  { name: "SATMO", link: SATMO },
  { name: "TACOM", link: TACOM },
  { name: "USACE", link: USACE },
  { name: "USAMMA", link: USAMMA },
  { name: "USASAC", link: USASAC },
]

const OrganizationPOCs = () => {
  const [orgPOCs, setOrgPOCs] = useState([])
  const [users, setUsers] = useState([])
  const [orgPOCRows, setOrgPOCRows] = useState([])
  const [isPOCModalOpen, setIsPOCModalOpen] = useState(false)
  const [POCModalType, setPOCModalType] = useState("assign")
  const [editModalParams, setEditModalParams] = useState({})
  const [csvData, setCsvData] = useState([])
  const [showDeletePOCWarningModal, setShowDeleteUserWarningModal] = useState(false)
  const [MUITableHeight, setMUITableHeight] = useState(800)
  const selectedPOCToRemoveFromOrganization = useRef("")

  const globalContext = useContext(GlobalContext)
  const { selectedOrg, setSelectedOrgContext, allOrgs, setAllOrgsContext } = globalContext

  const location = useLocation()

  const handleAssignPOC = () => {
    setPOCModalType("assign")
    setEditModalParams({})
    togglePOCModal()
  }

  const handleEditPOC = (params) => {
    setPOCModalType("edit")
    setEditModalParams(params.row)
    togglePOCModal()
  }

  const handleDeletePOC = (pocId) => {
    apiCalls.delete(`Organizations/${selectedOrg.id}/Pocs/${pocId}`).then(() => {
      getAllOrgsAndUsers(true)
      setShowDeleteUserWarningModal(false)
    })
  }

  const orgPOCCols = [
    {
      field: "id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    { field: "lastName", headerName: "Last name", flex: 2, headerClassName: "--grid-header", minWidth: 150 },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      headerClassName: "--grid-header",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="edit-delete-buttons">
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <EditIcon onClick={() => handleEditPOC(params)} />
            </button>
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <CloseIcon
                onClick={() => {
                  togglePOCDeleteWarningModal(params.row)
                }}
              />
            </button>
          </div>
        )
      },
    },
  ]

  // Set CSV headers
  const csvHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Role", key: "role" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Last Login", key: "lastLogin" },
    { label: "Status", key: "status" },
  ]

  const togglePOCDeleteWarningModal = (row) => {
    selectedPOCToRemoveFromOrganization.current = row
    setShowDeleteUserWarningModal(true)
  }

  const togglePOCModal = () => {
    if (isPOCModalOpen) {
      getAllOrgsAndUsers(true)
    }
    setIsPOCModalOpen((prevState) => !prevState)
  }

  const getAllOrgsAndUsers = async (isRefreshing) => {
    apiCalls.getAll(`Accounts`, {}).then((data) => {
      setUsers(data)
    })
    apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((tempOrgList) => {
      // Add org logo relative path to data object
      tempOrgList = tempOrgList.map((org) => {
        return { ...org, logo: logosArr.find((logo) => logo.name === org.name) }
      })
      setAllOrgsContext(tempOrgList)
      // isRefreshing will be true anytime we call this function after the initial page load
      if (isRefreshing) {
        const tmpSelectedOrg = tempOrgList.find((org) => org.id === selectedOrg.id)
        apiCalls.getById(`Organizations`, tmpSelectedOrg.id).then((apiOrg) => {
          setSelectedOrgContext({ ...apiOrg, logo: tmpSelectedOrg.logo })
        })
      } else {
        apiCalls.getById(`Organizations`, tempOrgList[0].id).then((apiOrg) => {
          setSelectedOrgContext({ ...apiOrg, logo: tempOrgList[0].logo })
        })
      }
    })
  }

  const getAllPOCs = () => {
    if (selectedOrg.id) {
      // Get Org POCs & update table, all fields will be included in selectedOrg.pocList
      setOrgPOCs(selectedOrg.pocList)
      let tempOrgPOCRows = []
      setOrgPOCRows([])
      selectedOrg.pocList.map((poc) => {
        const correspondingUser = users.find((user) => user.userName === poc.userName)
        tempOrgPOCRows.push({
          id: poc.id,
          firstName: poc.firstName,
          lastName: poc.lastName,
          email: poc.email,
          phone: poc.phoneNumber,
          role: poc.role ? poc.role : "",
          lastLogin: correspondingUser && correspondingUser.lastLoginDate ? correspondingUser.lastLoginDate : "",
          status: poc.activeState ? "Active" : "Not Active",
          actions: null,
        })
      })
      setOrgPOCRows(tempOrgPOCRows)
      // Get CSV data from orgPOCRows
      setCsvData(
        tempOrgPOCRows.map((poc) => {
          const { firstName, lastName, role, email, phone, lastLogin, status } = poc
          return { firstName, lastName, role, email, phone, lastLogin, status }
        })
      )
    }
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 425)
  }

  useEffect(() => {
    getAllPOCs()
  }, [allOrgs, selectedOrg])

  useEffect(() => {
    adjustTableHeight()
    if (selectedOrg.id) {
      getAllOrgsAndUsers(true)
    }
  }, [location])

  // This hook is called once when the page loads
  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
    if (!selectedOrg.id) {
      getAllOrgsAndUsers(false)
    }
  }, [])

  return (
    <div className="org-page-container">
      <div className="org-select">
        <div className="fms-organizations">
          <h2>
            <strong>FMS Organization POCs</strong>
          </h2>
        </div>
        <div className="data-download">
          <button onClick={() => window.print()}>
            <img
              src={pdfIcon}
              alt="PDF"
              width="50"
              height="50"
            />
          </button>
          <button>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`${selectedOrg.name}-pocs.csv`}
            >
              <img
                src={csvIcon}
                alt="CSV"
                width="50"
                height="50"
              />
            </CSVLink>
          </button>
        </div>
      </div>
      {POCModalType === "assign" ? (
        <AddOrganizationPOCModal
          isOpen={isPOCModalOpen}
          toggleModal={togglePOCModal}
          editParams={null}
          type="assign"
        />
      ) : (
        <AddOrganizationPOCModal
          isOpen={isPOCModalOpen}
          toggleModal={togglePOCModal}
          editParams={editModalParams}
          selectedOrg={selectedOrg}
          type="edit"
        />
      )}
      <div className="org-display">
        <div className="org-title">
          {selectedOrg.logo ? (
            <img
              src={selectedOrg.logo.link}
              alt=""
              width="80"
              height="80"
              className="org-icon"
            />
          ) : (
            <img
              src={orgIconPlaceholder}
              alt=""
              width="89"
              height="80"
              className="org-icon"
            />
          )}
          <h2 className="org-title-text">
            <strong>
              {selectedOrg.name} - {selectedOrg.longName}
            </strong>
          </h2>
        </div>
        <div className="org-table-contatiner">
          <div className="table-top">
            <h3 className="">
              <strong>Organization POCs</strong>
            </h3>
            <button
              className="assign-poc-btn"
              onClick={handleAssignPOC}
            >
              Assign POC
            </button>
          </div>
          <div style={{ height: MUITableHeight, minHeight: MinTableHeight, width: "100%" }}>
            <DataGrid
              rows={orgPOCRows}
              columns={orgPOCCols}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              pageSize={25}
              sx={{
                border: 2,
                borderColor: "black",
                marginTop: 2,
              }}
              getRowClassName={(params) => {
                if (params.indexRelativeToCurrentPage % 2 === 0) {
                  return "even-row"
                } else return "odd-row"
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: { id: false },
                },
              }}
            />
          </div>
        </div>
      </div>
      {/************************************************ [Replace CSV Data WARNING DIALOG WINDOW] ************************************************/}
      <Dialog
        open={showDeletePOCWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove POC from {selectedOrg.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you would like remove{" "}
            {selectedPOCToRemoveFromOrganization.current.firstName && selectedPOCToRemoveFromOrganization.current.lastName
              ? `${selectedPOCToRemoveFromOrganization.current.firstName} ${selectedPOCToRemoveFromOrganization.current.lastName}`
              : "this POC"}{" "}
            from the {selectedOrg.name} organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "black", border: "2px solid black" }}
            onClick={() => setShowDeleteUserWarningModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "white", fontWeight: "bold", border: "2px solid black" }}
            onClick={() => {
              handleDeletePOC(selectedPOCToRemoveFromOrganization.current.id)
              setShowDeleteUserWarningModal(false)
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OrganizationPOCs
