import React, { useEffect, useState } from 'react';

// Data
import { apiCalls } from '../components/DataService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import useReferenceUtils from './referenceUtils';

// MUI
import { Box, IconButton, Snackbar, Tab, Tabs, Button, PaginationItem } from '@mui/material';
import { GridActionsCellItem, DataGridPremium, useGridApiContext, useGridSelector, gridPageCountSelector, gridPageSelector  } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import MuiPagination from '@mui/material/Pagination';

// Custom
import CustomGridToolbarPremium from '../components/custom/CustomGridToolbarPremium';
import CustomTabPanel from '../components/custom/CustomTabPanel';
import Sidebar from './Sidebar';

// Vars
import { columnDefs } from './referenceUtils';

// Styles
import './styles/ReferenceTableViewStyles.css';
import ReferenceDialog from './ReferenceDialog';
import { ReferenceWarningDialog } from './ReferenceWarningDialog';
import { title } from 'process';

const ReferenceTableView = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });
  const [form, setForm] = useState({});
  const [modal, setModal] = useState({
    isOpen: false,
    mode: 'New',
  });
  const [warningOpen, setWarningOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [currentSideTab, setCurrentSideTab] = useState('CWAT');

  const getNewLists = (num) => {
    if (num === 1) {
      return useReferenceUtils().updatedColumnDefs;
    }
    if (num === 2) {
      return useReferenceUtils().updatedInputDefs;
    }
    if (num === 3) {
      return useReferenceUtils().updatedReqVar;
    }
    return useReferenceUtils();
  };

  let tabsDefs = [
    {
      title: "Hour Codes",
      endpoint: "GfebsHourCodes",
      colDefs: getNewLists(1).GfebsHourCodes
      //0 -- done
    },
    {
      title: "Gfebs Activity Id Pe Core Functions",
      endpoint: "GfebsActivityIdPeCoreFunctions",
      colDefs: getNewLists(1).GfebsActivityIdPeCoreFunctions,
      //1 -- done
    },
    {
      title: "Gfebs Funds Centers",
      endpoint: "GfebsFundsCenters",
      colDefs: getNewLists(1).GfebsFundsCenters,
      //2 -- done with org drop down
    },
    {
      title: "Gfebs Partner Wbs Elements",
      endpoint: "GfebsPartnerWbsElements",
      colDefs: getNewLists(1).GfebsPartnerWbsElements,
      //3 -- done
    },
    {
      title: "Organizations",
      endpoint: "Organizations",
      colDefs: getNewLists(1).Organizations,
      //4 -- done -- ask Mat what columns we need and post not working
    },
    {
      title: "Country Cocoms",
      endpoint: "CountryCocoms",
      colDefs: getNewLists(1).CountryCocoms,
      //5 -- done -- maybe rearrange the column order to have "COCOM Name" first
    },
    {
      title: "BPC Program Codes",
      endpoint: "BpcProgramCodes",
      colDefs: getNewLists(1).BpcProgramCodes,
      //6 -- done -- maybe center the single column?
    },
    {
      title: "POCS",
      endpoint: "Pocs",
      colDefs: getNewLists(1).Organizations,
      //7 -- not ready yet
    },
    {
      title: "RFI Organizations Mappings",
      endpoint: "RfiOrganizationsMappings",
      colDefs: getNewLists(1).Organizations,
      //8 -- not ready yet
    },
    {
      title: "Weighted Work Packages Counts",
      endpoint: "RfiOrganizationsMappings",
      colDefs: getNewLists(1).Organizations,
      //9 -- not ready yet, will leave this not working so that the "Forecasting Models" side tab shows 
    },
    {
      title: "Gfebs Cost Centers",
      endpoint: "GfebsCostCenters",
      colDefs: getNewLists(1).GfebsCostCenters,
      //10 -- done
    },
    {
      title: "Gfebs Functional Areas",
      endpoint: "GfebsFunctionalAreas",
      colDefs: getNewLists(1).GfebsFunctionalAreas,
      //11 -- done
    },
    {
      title: "Program Element Core Functions",
      endpoint: "ProgramElementCoreFunctions",
      colDefs: getNewLists(1).ProgramElementCoreFunctions,
      //12 -- done
    },
    {
      title: "Workforce Records",
      endpoint: "WorkforceRecords",
      colDefs: getNewLists(1).Organizations,
      //13 -- maybe done, mat created post method. Will test post functionality when deployed to dev
    },
    {
      title: "Preparing Activity Codes",
      endpoint: "DsamsPreparingActivityCodes",
      colDefs: getNewLists(1).DsamsPreparingActivityCodes,
      //14 -- done
    },
    {
      title: "Preparing Activity Datas",
      endpoint: "DsamsPreparingActivityDatas",
      colDefs: getNewLists(1).Organizations,
      //15 -- not ready
    },
    {
      title: "Preparing Activity Data RFIS",
      endpoint: "DsamsPreparingActivityDataRfis",
      colDefs: getNewLists(1).Organizations,
      //16 -- done
    },
    {
      title: "Ric Records",
      endpoint: "RicRecords",
      colDefs: getNewLists(1).RicRecords,
      //17 -- done
    },
    {
      title: "Organization Mappings",
      endpoint: "OrganizationMappings",
      colDefs: getNewLists(1).Organizations,
      //18 -- not ready
    },
  ];

  const groupedTabs = {
    CWAT: [tabsDefs[4],tabsDefs[5],tabsDefs[6],],
    // pending CWAT: tabsDefs[7],tabsDefs[8],
    'Forecasting Models': [tabsDefs[9],],
    // pending 'Forecasting Models': tabsDefs[9],
    Workforce: [tabsDefs[0],tabsDefs[1],tabsDefs[2],tabsDefs[3],tabsDefs[10],tabsDefs[11],tabsDefs[12],],
    // pending Workforce: tabsDefs[13],
    Workload: [tabsDefs[14],tabsDefs[17],],
    // pending Workload: tabsDefs[15],tabsDefs[18],tabsDefs[16],
  };

  const [selectedGroup, setSelectedGroup] = useState('CWAT');
  
  const handleGroupChange = (group) => {
    setSelectedGroup(group);
    setCurrentSideTab(group);
    const newTab = groupedTabs[group][0];
    setCurrentTab({
      num: 0,
      name: newTab.title,
      endpoint: newTab.endpoint,
      colDefs: newTab.colDefs,
    });
    fetchData(newTab.endpoint);
  };

  const [currentTab, setCurrentTab] = useState({
    num: 0,
    open: false,
    name: tabsDefs[4].title,
    endpoint: tabsDefs[4].endpoint,
    colDefs: tabsDefs[4].colDefs,
  });

  useEffect(() => {
    fetchData(currentTab.endpoint);
  }, []);

  useEffect(() => {
    data.length && setLoading(false);
    !data.length && setLoading(true);
  }, [data]);

  const fetchData = async (apiType) => {
    let response = await apiCalls.fetchData(apiType);
    let cleanData = response?.data ? response.data : response;
    setData(Array.isArray(cleanData) ? cleanData : []);
  };

  const tabProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      disableRipple: true,
      variant: 'tabbedView',
    };
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  const handleTabChange = async (e, newValue) => {
    setData([]);
    const newTab = groupedTabs[selectedGroup][newValue];
    setCurrentTab({
      num: newValue,
      name: newTab.title,
      endpoint: newTab.endpoint,
      colDefs: newTab.colDefs,
    });
    await fetchData(newTab.endpoint);
  };

  const newTab = (tab, index) => {
    let { title } = tab;
    return (
      <Tab key={title} name={title} label={title} {...tabProps(index)} />
    );
  };

  const getTabs = () => {
    return groupedTabs[selectedGroup].map((item, index) => {
      return newTab(item, index);
    });
  };

  const Toolbar = () => {
    return (
      <CustomGridToolbarPremium>
        <AddButton />
      </CustomGridToolbarPremium>
    );
  };

  const handleModalClick = (mode) => {
    setForm({})
    switch (mode) {
      case 'New':
        setModal({
          isOpen: true,
          mode: 'New',
        });
        break;

      default:
        break;
    }
  };

  const AddButton = () => {
    return (
      <Button
        variant="gridAddBtn"
        onClick={() => handleModalClick('New')}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
          margin: 1,
        }}
      >
        <AddIcon fontSize="small" />
        NEW
      </Button>
    );
  };

  const handleEditClick = (row) => {
    setForm(row);
    setModal({
      isOpen: true,
      mode: 'Edit',
    });
  };

  const handleDeleteWarningClick = (row) => {
    setRowId(row.id);
    setWarningOpen(true);
  };

  const handleDelete = async () => {
    let response = await apiCalls.testDelete(currentTab.endpoint, rowId);
    fetchData(currentTab.endpoint);
    setSnackbar({
      open: true,
      message: 'Item deleted successfully.',
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
    });
  };

  const snackbarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() =>
        setSnackbar({
          open: false,
          message: '',
        })
      }
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleSnackbarSuccess = () => {
    let str = modal.mode === 'New' ? 'Item has been successfully added.' : 'Changes have been saved.';
    setSnackbar({
      open: true,
      message: str,
    });
    fetchData(currentTab.endpoint);
    setModal({
      isOpen: false,
      mode: 'New',
    });
  };

  function Pagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handlePageChange = (event, newPage) => {
      apiRef.current.setPage(newPage - 1);
    };

    return (
      <MuiPagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              fontWeight: item.page === page + 1 ? 'bold' : 'normal',
            }}
          />
        )}
      />
    );
  }

  function CustomPagination(props) {
    return <Pagination {...props} />;
  }

  return (
    <Box className="viewContainer" display="flex" sx={{ height: '90vh', width: '99vw' }}>
      <Sidebar onGroupChange={handleGroupChange} currentSideTab={currentSideTab} />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginLeft: '125px' }}>
        <Tabs
          variant="scrollable"
          value={currentTab.num}
          onChange={handleTabChange}
          sx={{
            width: '100%',
            marginBottom: '0px',
            borderColor: 'divider',
            '.MuiTabs-flexContainer': {
              justifyContent: 'space-evenly',
              overflow: 'visible',
              marginLeft: '0px'
            },
            '.MuiTabs-scrollableX': {
              overflowX: 'auto',
            },
          }}
        >
          {getTabs()}
        </Tabs>

        {/* TAB PANEL - DATA GRID */}
        <CustomTabPanel sx={{ flexGrow: 1 }}>
          <Box className="dataGridContainer" sx={{ flexGrow: 1, width: '99%' }}>
            {!loading && data.length > 0 && (
              <DataGridPremium
                disableRowSelectionOnClick
                density="compact"
                pagination
                pageSize={paginationModel.pageSize}
                page={paginationModel.page}
                onPageChange={(params) => handlePageChange(params.page)}
                onPageSizeChange={(params) => handlePageSizeChange(params.pageSize)}
                rowsPerPageOptions={[25, 50, 100]}
                rowCount={data.length}
                loading={loading}
                rows={data}
                columns={[
                  ...currentTab.colDefs,
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    headerClassName: 'actions',
                    getActions: (params) => [
                      <GridActionsCellItem
                        key={params.row.id}
                        icon={<EditIcon />}
                        onClick={() => handleEditClick(params.row)}
                        label="Edit"
                        showInMenu
                      />,
                      <GridActionsCellItem
                        key={params.value}
                        icon={<DeleteForeverIcon />}
                        onClick={() => handleDeleteWarningClick(params.row)}
                        label="Delete"
                        showInMenu
                      />,
                    ],
                  },
                ]}
                components={{
                  Toolbar: Toolbar,
                  Pagination: CustomPagination,
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                  pagination: {
                    paginationModel: paginationModel,
                  },
                }}
                sx={{
                  maxHeight: '74vh'
                }}
              />
            )}
          </Box>
        </CustomTabPanel>

        {/* FORM DIALOG */}
        <ReferenceDialog
          modal={modal}
          currentTab={currentTab}
          setModal={setModal}
          form={form}
          setForm={setForm}
          setSnackbar={setSnackbar}
          handleSnackbarSuccess={handleSnackbarSuccess}
        />

        {/* WARNING DIALOG */}
        <ReferenceWarningDialog
          handleDelete={handleDelete}
          open={warningOpen}
          setOpen={setWarningOpen}
        />

        {/* SNACKBAR */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbar.message}
          action={snackbarAction}
        />
      </Box>
    </Box>
  );
};

export default ReferenceTableView;
