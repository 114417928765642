import { useContext } from "react"

// Context
import AppContext from "./AppContext"

// Libraries
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

// Custom
import ACC from "./components/RFIs/ACC/ACC"
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes"
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants"
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute"
import DashboardMenu from "./components/DashboardMenu"
import DSAMS_RFI from "./components/RFIs/DSAMS-CISIL/DSAMS_RFI"
import FiscalCycles from "./components/Admin/FiscalCycles/FiscalCycles"
import GFEBS_CEFMS from "./components/RFIs/GFEBS-CEFMS/GFEBS_CEFMS"
import LandingPage from "./LandingPage/LandingPage"
import ManHourWeightedLinesModelView from "./components/Admin/MHWLModels/ManHourWeightedLinesModelView"
import OrganizationPOCs from "./components/Organizations/OrganizationPOCs"
import PageNotFound from "./components/PageNotFound"
import PEO_PM from "./components/RFIs/PEO-PM/PEO_PM"
import RFIValidationDashboardMenu from "./components/RFIValidationDashboardMenu"
import SATFA from "./components/RFIs/SATFA/SATFA"
import SAPBR from "./components/Admin/SAPBR/SAPBR"
import SupplementalWorkforce from "./components/RFIs/Supplemental-Workforce/SupplementalWorkforce"
import TDA from "./components/RFIs/TDA/TDA"
import Users from "./components/Admin/Users/Users"

//Styles
import LoadingView from "./components/loading/LoadingView"
import ReferenceTableView from "./reference-tables/ReferenceTableView"

const AppRoutes = ({ loading }) => {
  const context = useContext(AppContext)
  const { classificationData, clickTarget, selectedFY } = context
  let user = sessionStorage.getItem("currentUser")

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div
        className="cui-top"
        style={{ backgroundColor: classificationData.classificationColor }}
      >
        <strong>{classificationData.classificationMarking}</strong>
      </div>
      <Route render={({ location }) => location.pathname !== "/cwat-web" && <DashboardMenu clickTarget={clickTarget} />} />
      {loading && <LoadingView />}
      {!loading && (
        <Switch>
          {/* Authentication Routes */}
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />
          {/* Dashboard Redirect Route */}
          <Route
            exact
            path="/"
            render={() => <Redirect to="/cwat-web" />}
          />

          {/* Landing Page */}
          <AuthorizeRoute
            exact
            path="/cwat-web"
            element={<LandingPage />}
          />

          {/* Reference Table View */}
          <AuthorizeRoute
            exact
            path="/reference"
            element={<ReferenceTableView />}
          />

          {/* Organization Routes */}
          <AuthorizeRoute
            exact
            path="/organization-POCs"
            element={<OrganizationPOCs />}
          />

          {/* RFI Views */}
          <AuthorizeRoute
            exact
            path="/DSAMS-CISIL-RFIs"
            element={<DSAMS_RFI />}
          />
          <AuthorizeRoute
            exact
            path="/GFEBS-CEFMS-RFIs"
            element={<GFEBS_CEFMS />}
          />
          <AuthorizeRoute
            exact
            path="/ACC-RFIs"
            element={<ACC />}
          />
          <AuthorizeRoute
            exact
            path="/SupplementalWorkforce-RFIs"
            element={<SupplementalWorkforce />}
          />
          <AuthorizeRoute
            exact
            path="/SATFA-RFIs"
            element={<SATFA />}
          />
          <AuthorizeRoute
            exact
            path="/PEO-PM-RFIs"
            element={<PEO_PM />}
          />
          <AuthorizeRoute
            exact
            path="/TDA-RFIs"
            element={<TDA />}
          />

          {/* Admin Routes */}
          <AuthorizeRoute
            exact
            path="/users"
            element={<Users />}
          />
          <AuthorizeRoute
            exact
            path="/SAPBR"
            element={<SAPBR />}
          />
          <AuthorizeRoute
            exact
            path="/FiscalCycles"
            element={<FiscalCycles />}
          />
          <AuthorizeRoute
            exact
            path="/Dashboards"
            element={<RFIValidationDashboardMenu />}
          />
          <AuthorizeRoute
            exact
            path="/Models"
            element={<ManHourWeightedLinesModelView />}
          />

          {/* Page Not Found */}
          <Route
            path="/*"
            element={<PageNotFound />}
          />
        </Switch>
      )}
      <div
        className="cui-bottom"
        style={{ backgroundColor: classificationData.classificationColor }}
      >
        <strong>{classificationData.classificationMarking}</strong>
      </div>
    </BrowserRouter>
  )
}

export default AppRoutes
