import { useContext, useState, useEffect } from "react"

// Libraries
import { useLocation } from "react-router-dom"

// MUI
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"

//Data
import { apiCalls } from "../DataService"

// Custom
import { logosArr } from "./OrganizationPOCs"
import GlobalContext from "../reducers/GlobalContext"

// Styles
import "./OrganizationPOCs.css"

export const OrganizationSelectDropdown = ({ clickTarget, pathname }) => {
  const location = useLocation()
  const globalContext = useContext(GlobalContext)
  const {
    selectedOrg,
    setSelectedOrgContext,
    allOrgs,
    filteredOrgList,
    setFilteredOrgListContext,
    rfiOrganizationsMapping,
    selectedTabRfiMappingKey,
  } = globalContext

  const [showOrgListDropwdown, setShowOrgListDropdown] = useState(false)
  const mappings = rfiOrganizationsMapping[selectedTabRfiMappingKey]

  // ** Effects
  useEffect(() => {
    if (location.pathname === "/organization-POCs") {
      setFilteredOrgListContext(allOrgs)
      return
    }
    if (location.pathname === "/Dashboards" || location.pathname === "/users") {
      setFilteredOrgListContext(allOrgs.filter((org) => org.name === "DASA DE&C"))
      return
    }
    if (mappings) {
      setFilteredOrgListContext(allOrgs.filter((org) => mappings.includes(org.name)))
    } else {
      setFilteredOrgListContext(allOrgs)
    }
  }, [selectedTabRfiMappingKey, rfiOrganizationsMapping, allOrgs, location])

  useEffect(() => {
    if (clickTarget?.className !== "navbar-button") {
      if (showOrgListDropwdown) {
        setShowOrgListDropdown(false)
      }
    }
  }, [clickTarget])

  // ** Functions
  const updateSelectedOrg = (org) => {
    apiCalls.getById(`Organizations`, org.id).then((selectedOrg) => {
      setSelectedOrgContext({ ...selectedOrg, logo: logosArr.find((logo) => logo.name === selectedOrg.name) })
    })
    setShowOrgListDropdown(false)
  }

  const getMenuItems = () => {
    return filteredOrgList.map((item, index) => (
      <MenuItem
        key={index}
        value={item.name}
        onClick={() => updateSelectedOrg(item)}
        className="dashNavBtn"
      >
        <Typography>{item.name}</Typography>
      </MenuItem>
    ))
  }

  return (
    <FormControl className="dashNavBtn">
      <InputLabel
        id="plz"
        className="navButton"
        sx={{ color: "white" }}
      >
        Organization
      </InputLabel>
      <Select
        className="navButton dashBtn dashNavBtn"
        label="Organization"
        value={selectedOrg.name || ""}
        renderValue={(a) => {
          return <Typography variant="navbarBtn">{a}</Typography>
        }}
        sx={{
          marginRight: "5px",
          padding: "5px 15px",

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          ".MuiSelect-icon": {
            color: "white",
          },
          ".MuiOutlinedInput-input": {
            padding: "0px",
          },
        }}
      >
        {getMenuItems()}
      </Select>
    </FormControl>
  )
}
