import { Box } from "@mui/material"

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ minWidth: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export default CustomTabPanel
