import React from 'react'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import '../RFI.css'

const SupplementalWorkforce_Instructions_Modal = ({disclaimerRFIModalOpen, setDisclaimerRFIModalOpen}) => {
    return (
        <Modal
            open={disclaimerRFIModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        > 
            <div className='--RFI-disclaimer-modal-container'>
                <div className='--RFI-disclaimer-modal-header'>
                    <h4>RFI Instructions - BPC CIV FTE Reduction</h4>
                    <CloseIcon fontSize='large' onClick={() => setDisclaimerRFIModalOpen(false)}/>
                </div>
                <div className='--RFI-disclaimer-modal-body'>
                    <div className='--RFI-disclaimer-modal-messages'>
                        <div>
                            <p>If your organization cost transferred from FMS Admin to BPC in the most recently completed FY, please identify the corresponding civilian FTEs to reduce from your FMS Admin CIV FTE count.  Please only identify FTEs in this section if FMS Admin dollars transferred but the hours charged did not in the CIV time charging RFI response you uploaded. </p>
                        </div>
                    </div>
                    <button className='--RFI-close-RFI-modal-btn' onClick={() => setDisclaimerRFIModalOpen(false)}>Close</button>
                </div>
            </div>
        </Modal>
    )
}

export default SupplementalWorkforce_Instructions_Modal
